<template>
	<el-scrollbar class="teacher-page">
		<div class="t-page">
			<div class="t-page-title">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ name: 'teacherMarketIndex' }">{{ $t('teacher.breadcrumb.contentManege') }}</el-breadcrumb-item>
					<el-breadcrumb-item>{{ $t('teacher.breadcrumb.marketTag') }}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="t-page-screen">
				<el-form inline size="small">
					<el-form-item :label="$t('teacher.formItem.tagName')">
						<el-input v-model="listQuery.title" clearable style="width: 200px;"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="handleFilter" icon="el-icon-search"></el-button>
						<el-button type="text" @click="handleClear" icon="el-icon-brush">{{ $t('teacher.actions.clear') }}</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="t-page-actions">
				<el-button type="success" size="small" @click="handleAddShow">{{ $t('teacher.actions.addTag') }}</el-button>
			</div>
			<div class="t-page-table">
				<el-table v-loading="listLoading" :data="pageList">
					<el-table-column :label="$t('teacher.formItem.tagName')">
						<template slot-scope="scope">{{ scope.row.label_name }}</template>
					</el-table-column>
					<el-table-column :label="$t('teacher.tableHead.activityCate')">
						<template slot-scope="scope">
							<div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
								@click="handleEditShow(scope)">{{ scope.row.cate_name }}</div>
						</template>
					</el-table-column>
					<el-table-column :label="$t('teacher.tableHead.actions')">
						<template slot-scope="scope">
							<el-button type="primary" size="mini" @click="handleEditShow(scope)">{{ $t('teacher.actions.edit') }}</el-button>
							<el-button v-if="scope.row.can_delete === 1" type="danger" size="mini" @click="handleDelete(scope)">{{ $t('teacher.actions.delete') }}</el-button>
						</template>
					</el-table-column>
				</el-table>
				<pagination v-show="pageTotal > 0" :total="pageTotal" :page.sync="pageCurr" :limit.sync="pageSize"
					@pagination="getList"></pagination>
			</div>
			<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="600px"
				:before-close="handleDialogClose">
				<el-form ref="postForm" :model="postForm" :rules="postRules" label-width="100px">
					<el-form-item :label="$t('teacher.formItem.marketTag')" prop="label_name">
						<el-input v-model="postForm.label_name" maxlength="8" />
					</el-form-item>
					<el-form-item :label="$t('teacher.tableHead.activityCate')" prop="goods_cate_ids">
						<el-cascader v-model="postForm.goods_cate_ids" :options="cateOptions"
							:props="{ value: 'cate_id', label: 'cate_name', multiple: true }" collapse-tags filterable
							popper-class="cascader-category" />
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button @click="handleDialogClose">{{ $t('teacher.actions.cancel1') }}</el-button>
					<el-button v-loading="submitLoading" type="primary" @click="handleSubmit">{{ $t('teacher.actions.confirm1') }}</el-button>
				</div>
			</el-dialog>
		</div>
	</el-scrollbar>
</template>
  
<script>
import Pagination from '@/components/seller/pagination'
import { OSSUpload } from '@/utils/upload'
import { fetchTagsList, fetchMarketCate, fetchTagsInfo, createTags, updateTags, deleteTags } from '@/api/teacher'
const defaultQuery = {
	title: ''
}
const defaultForm = {
	label_name: '',
	icon_url: '',
	goods_cate_ids: []
}
export default {
	components: { Pagination },
	data() {
		const validateRequire = (rule, value, callback) => {
			if (value === '') {
				callback(new Error(this.$t('teacher.validate.required')))
			} else {
				callback()
			}
		}
		const validateRequireCate = (rule, value, callback) => {
			if (value.length > 0) {
				callback()
			} else {
				callback(new Error(this.$t('teacher.validate.required')))
			}
		}
		return {
			listLoading: false,
			pageList: [],
			pageCurr: 1,
			pageSize: 10,
			pageTotal: 0,
			listQuery: Object.assign({}, defaultQuery),
			dialogVisible: false,
			dialogTitle: this.$t('teacher.dialogTitle.addTag'),
			isEdit: false,
			cateOptions: [],
			postForm: Object.assign({}, defaultForm),
			postRules: {
				goods_cate_ids: [{ required: true, validator: validateRequireCate }],
				label_name: [{ required: true, validator: validateRequire }]
			},
			uploadLoading: false,
			submitLoading: false
		}
	},
	created() {
		this.getData()
	},
	methods: {
		async getData() {
			try {
				const category = await fetchMarketCate()
				this.cateOptions = category.data.list
			} catch (error) {
				console.log(error)
			}
			this.getList()
		},
		getList() {
			this.listLoading = true
			const params = {
				...this.listQuery,
				page_index: (this.pageCurr - 1) * this.pageSize,
				page_size: this.pageSize
			}
			fetchTagsList(params).then(response => {
				const { data } = response
				this.pageList = data.list
				this.pageTotal = data.count
				this.listLoading = false
			})
		},
		handleFilter() {
			this.pageCurr = 1
			this.getList()
		},
		handleClear() {
			this.listQuery = Object.assign({}, defaultQuery)
			this.handleFilter()
		},
		handleAddShow() {
			this.postForm = Object.assign({}, defaultForm)
			this.dialogTitle = this.$t('teacher.dialogTitle.addTag')
			this.isEdit = false
			this.dialogVisible = true
		},
		handleEditShow({ row }) {
			this.isEdit = true
			this.dialogTitle = this.$t('teacher.dialogTitle.editTag')
			fetchTagsInfo({ activity_label_id: row.activity_label_id }).then(response => {
				const { data } = response
				this.postForm = Object.assign({}, data)
				this.dialogVisible = true
			})
		},
		handleDelete({ row }) {
			this.$confirm(this.$t('teacher.confirm.deleteTag'), this.$t('teacher.confirm.title'), {
				confirmButtonText: this.$t('teacher.actions.confirm1'),
				cancelButtonText: this.$t('teacher.actions.cancel1'),
				type: 'warning'
			}).then(async () => {
				await deleteTags({ activity_label_id: row.activity_label_id })
				this.getList()
				this.$message.success(this.$t('teacher.successMsg.delete'))
			}).catch(() => { })
		},
		async httpRequest(file) {
			const isType = ['image/jpeg', 'image/png'].includes(file.type)
			const isSize = file.size / 1024 / 1024 < 3
			if (!isType) {
				this.$message.error(this.$t('teacher.errorMsg.imageType'))
				return false
			}
			if (!isSize) {
				this.$message.error(this.$t('teacher.errorMsg.imageSize3M'))
				return false
			}
			this.uploadLoading = true
			const { url } = await OSSUpload(file, 'market')
			if (url) {
				this.postForm.icon_url = url
			} else {
				this.$message.error(this.$t('teacher.errorMsg.upload'))
			}
			this.$refs.postForm.validateField('icon_url')
			this.uploadLoading = false
		},
		handleRemove() {
			this.postForm.icon_url = ''
		},
		handleDialogClose() {
			this.dialogVisible = false
		},
		handleSubmit() {
			this.$refs.postForm.validate(async valid => {
				if (valid) {
					this.submitLoading = true
					const params = { ...this.postForm }
					try {
						if (this.isEdit) {
							await updateTags(params)
							this.$message.success(this.$t('teacher.successMsg.update'))
						} else {
							await createTags(params)
							this.$message.success(this.$t('teacher.successMsg.add'))
						}
					} catch (error) {
						console.log(error)
					}
					this.getList()
					this.dialogVisible = false
					this.submitLoading = false
				} else {
					return false
				}
			})
		}
	}
}
</script>
  
<style lang="scss" scoped>
.t-page {
	padding: 24px;

	.t-page-title {
		padding-bottom: 20px;
		margin-bottom: 20px;

		.title {
			line-height: 20px;
			font-size: 16px;
			font-weight: bold;
		}
	}

	.t-page-screen {
		.el-button--text {
			color: #777;
		}
	}

	.t-page-actions {
		margin-bottom: 15px;
	}
	.t-page-table {
		::v-deep .el-table {
			th.el-table__cell {
				background-color: $--seller-thead-background-1;
			}
		}
	}
}

.upload-container {
	width: 120px;
	height: 120px;
	position: relative;

	.upload-action {
		height: 100%;
		border: 1px dashed #d9d9d9;
		border-radius: 6px;

		&:hover {
			border-color: #409EFF;
		}

		.el-upload__text {
			line-height: 24px;

			p {
				margin: 0;
			}
		}
	}

	.el-icon-plus {
		font-size: 24px;
	}

	.upload-preview {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		border-radius: 6px;
		align-items: center;
		justify-content: center;
		border: 1px dashed #d9d9d9;
		background-color: #FFFFFF;
		position: absolute;

		.upload-preview-icon {
			width: 60%;
		}

		.upload-preview-action {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			cursor: default;
			text-align: center;
			color: #fff;
			opacity: 0;
			font-size: 24px;
			background-color: rgba(0, 0, 0, .5);
			transition: opacity .3s;
			cursor: pointer;
			text-align: center;
			line-height: 120px;
		}

		&:hover {
			.upload-preview-action {
				opacity: 1;
			}
		}
	}
}
</style>
  